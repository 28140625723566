import gql from "graphql-tag";
import { Voucher, VoucherCreateInput, VoucherEdgesResponse, VoucherResponse, VoucherSearchInput, VoucherUpdateInput } from "../gen/types";
import { ResponseGql } from "../types/MDOTypes";
import { BaseService } from "./BaseService";

export class VoucherService extends BaseService {

  constructor() {
    super();
  }

  get(id: string): ResponseGql<Voucher> {
    const query = gql`query voucher($id: ID!) {
      voucher(id: $id) {
        id
        name
        description
        discountType
        discountValue
        maxCodes
        allowedResults
        outsideBooks{
          id
          name
          qrCode{
            link
          }
        }
        expiredAt {
          iso
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { id: id }})
    .then((result) => result.data.voucher);
  }

  getAll(voucherSearch: VoucherSearchInput): ResponseGql<VoucherEdgesResponse> {
    const query = gql`query vouchers($voucherSearch: VoucherSearchInput) {
      vouchers(params: $voucherSearch) {
        edges {
          id
          name
          description
          discountLabel
          discountType
          discountValue
          maxCodes
          qtUsed
          qtDrawn
          expiredAt {
            iso
          }
          outsideBooks{
            name
          }
        }
        pagination {
          total
          page
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: voucherSearch })
    .then((result) => result.data.vouchers);
  }

  create(voucher: VoucherCreateInput): ResponseGql<VoucherResponse> {
    const mutation = gql`mutation createVoucher($createVoucher: VoucherCreateInput!) {
      createVoucher(input: $createVoucher) {
        message
        data {
          id
        }
      }
    }`;

    return this.apolloClient.mutate({ mutation: mutation, variables: { createVoucher: voucher }})
    .then((result) => result.data.createVoucher);
  }

  update(id: string, voucher: VoucherUpdateInput): ResponseGql<VoucherResponse> {
    const mutation = gql`mutation updateVoucher($id: ID!, $updateVoucher: VoucherUpdateInput!) {
      updateVoucher(id: $id, input: $updateVoucher) {
        message
        data {
          id
        }
      }
    }`;

    return this.apolloClient.mutate({ mutation: mutation, variables: { id: id, updateVoucher: voucher }})
    .then((result) => result.data.updateVoucher);
  }

  remove(id: string): ResponseGql<VoucherResponse> {
    const remove = gql`mutation removeVoucher($id: ID!) {
      removeVoucher(id: $id) {
        message
        data {
          id
        }
      }
    }`;

    return this.apolloClient.mutate({ mutation: remove, variables: { id: id }})
    .then((result) => result.data.removeVoucher);
  }

  detail(id: string): ResponseGql<Voucher> {
    const query = gql`query voucherDetails($id: ID!) {
      voucher(id: $id) {
        name
        description
        discountType
        discountValue
        codes {
          code
          exam {
            type
            from
            bookIn
            bookOut
            sheet
            agent {
              name
            }
            campaign {
              name
            }
            account {
              name
            }
            client {
              name
              whatsapp
            }
            status
          }
          usedAt {
            iso
            transform(format: "DD/MM/YYYY HH:mm")
          }
          createdAt {
            iso
          }
        }
        maxCodes
        qtUsed
        qtDrawn
        allowedResults
        expiredAt {
          iso
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { id: id }})
    .then(result => result.data.voucher);
  }
}
